<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Genie Coin / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addGenieCoinForm" #f="ngForm">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Price <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Price"
                                            formControlName="price"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addGenieCoinForm.get('price').hasError('required') && (addGenieCoinForm.get('price').dirty || addGenieCoinForm.get('price').touched)">
                                                *Please enter price.
                                            </span>
                                       
                                            <!-- <span
                                                *ngIf="addGenieCoinForm.get('price').hasError('maxlength') && !(addGenieCoinForm.get('price').hasError('pattern')) && addGenieCoinForm.get('price').dirty ">
                                                *Maximum length should not more than 18.
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright ">currency <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Currency"
                                            formControlName="currency" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addGenieCoinForm.get('currency').hasError('required') && (addGenieCoinForm.get('currency').dirty || addGenieCoinForm.get('currency').touched)">
                                                *Please enter currency.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Coin <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Coin"
                                            formControlName="coin"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addGenieCoinForm.get('coin').hasError('required') && (addGenieCoinForm.get('coin').dirty || addGenieCoinForm.get('coin').touched)">
                                                *Please enter coin.
                                            </span>
                                       
                                            <!-- <span
                                                *ngIf="addGenieCoinForm.get('coin').hasError('maxlength') && !(addGenieCoinForm.get('coin').hasError('pattern')) && addGenieCoinForm.get('coin').dirty ">
                                                *Maximum length should not more than 18.
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/genie-coin-list">Back</button>

                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addGenieCoinForm.valid"
                            (click)="addGenieCoin()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>