import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form: FormGroup;
  resetId: any;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})+$/)]))
    })
  }
  get f() { return this.form.controls }

  submit() {
    if (this.form.valid) {
      const data = {
        email: this.form.value.email
      }
      this.commonService.showSpinner()
      this.commonService.postApi(ApiEndPoint.forgotPassword, data, 0).subscribe((res: any) => {
        // console.log('forgot password response==>', res);
        if (res.code == 200) {
          this.commonService.hideSpinner()
          this.commonService.successToast(res.message)
          this.router.navigate(['login'])
          // this.resetId = res.result;
          // this.router.navigate(['/otp'], { queryParams: { email: window.btoa(this.form.value.email) } })
          this.form.reset()
        } else {
          this.commonService.hideSpinner()
          this.commonService.errorToast(res.message)
        }
      })
    }
    else {
      this.commonService.errorToast('Email is required.')
      // console.log('Email is required.');
    }

  }
}
