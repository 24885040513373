<div class="wrapper">
    <main class="middle-content">
      <div class="page_title_block">
        <h1 class="page_title">Profile / Change Password</h1>
      </div>
      <div class="content-section">
        <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
          <div class="  mb40">
            <div class="admin-profile">
              <h4 class="mb20 text-center">Change Password</h4>
            </div>
            <div class="add-store-block input-style mb20 mt20 ">
              <form [formGroup]="form" #f="ngForm">
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">Current Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Current Password" formControlName="oldPassword"
                        (keypress)="commonService.preventSpace($event)" maxlength="30">
                      <div
                        *ngIf="form.get('oldPassword').invalid && (form.get('oldPassword').touched || form.get('oldPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('oldPassword').hasError('required') ">*Please enter old password.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">New Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="New Password" formControlName="newPassword"
                        (keypress)="commonService.preventSpace($event)" maxlength="30">
                      <div
                        *ngIf="form.get('newPassword').invalid && (form.get('newPassword').touched || form.get('newPassword').dirty)"
                        class="text-danger">
                        <span *ngIf="form.get('newPassword').hasError('required')">
                          *Please enter new password.</span>
                        <span *ngIf="form.get('newPassword').hasError('pattern')">
                          *Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1
                          number and 1 special character.</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row align-items-baseline view-label-align">
                  <label class="col-md-5 textalignright ">Confirm New Password <span>:</span></label>
                  <div class="col-md-7 textaligncenter">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Confirm New Password"
                        formControlName="confirmPassword" (keypress)="commonService.preventSpace($event)" maxlength="30">
                      <div *ngIf="form.get('confirmPassword').dirty" class="text-danger">
                        <span *ngIf="form.get('confirmPassword').value != form.get('newPassword').value">*Password
                          mismatched.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="text-center mt40 mb40">
              <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="form.invalid"
                (click)="changePassword()">Change</button>
              <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Back</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>