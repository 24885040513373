import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.loginFormValidation();
    var rememberMe = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    if (rememberMe != '') {
      this.loginForm.patchValue({
        email: window.atob(rememberMe.email),
        password: window.atob(rememberMe.password),
        rememberMe: window.atob(rememberMe.rememberMe)
      })
    }
  }

  //-------  login form validation -------- //
  loginFormValidation() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', Validators.required),
      rememberMe: new FormControl(false)
    });
  }

  // ---------  login form submit ------------- //
  login() {
    let data = {
      'email': this.loginForm.value.email,
      'password': this.loginForm.value.password
    }
    this.commonService.showSpinner()
    this.commonService.postApi(ApiEndPoint.login, data, 0).subscribe((res: any) => {
      console.log("login response ==>", res)
      if (res.code == 200) {
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message)
        // genie admin token
        localStorage.setItem('genieToken', res.data.token);
        this.commonService.loginStatus.next(true)
        this.getProfile()
        this.router.navigate(['dashboard'])
        // console.log(this.loginForm.value.rememberMe)
        if (this.loginForm.value.rememberMe) {
          let remData = {
            "email": window.btoa(this.loginForm.value.email),
            "password": window.btoa(this.loginForm.value.password),
            "rememberMe": window.btoa(this.loginForm.value.rememberMe)
          }
          localStorage.setItem('rememberMe', JSON.stringify(remData))
        }
        else {
          localStorage.removeItem("rememberMe");
        }
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  // get profile
  getProfile() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.profile, 1).subscribe((res: any) => {
      console.log("sidebar profile response ==>", res);
      if (res.code == 200) {
        // this.profileData = res.data
        this.commonService.loginData.next(res.data)
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }
    })
  }

}
