<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Static Content / Edit</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <form [formGroup]="editStaticContentForm">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class=" order-view main-block-innner mb20 mt40 view-label-align">
                                        <div class="add-store-block input-style">
                                            <div class="form-group row ">
                                                <div class="col-md-12">
                                                    <div class="form-group row align-items-center">
                                                        <label class="col-md-4"> Title <span>:</span></label>
                                                        <div class="col-md-8">
                                                            <input type="text" class="form-control" maxlength="60"
                                                                formControlName="title">
                                                            <div *ngIf="editStaticContentForm.get('title').invalid && (editStaticContentForm.get('title').dirty || editStaticContentForm.get('title').touched)"
                                                                class="text-danger">
                                                                <span
                                                                    *ngIf="editStaticContentForm.get('title').hasError('required')">
                                                                    *Title is required.</span>
                                                                <span
                                                                    *ngIf="editStaticContentForm.get('title').hasError('pattern')">
                                                                    *Please enter valid title.</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <ng-container *ngIf="staticContentType == 'contactUs'">
                                                        <div class="form-group row align-items-center">
                                                            <label class="col-md-4"> Email <span>:</span></label>
                                                            <div class="col-md-8">
                                                                <input type="text" class="form-control" maxlength="60"
                                                                    formControlName="email">
                                                                <div *ngIf="editStaticContentForm.get('email').invalid && (editStaticContentForm.get('email').dirty || editStaticContentForm.get('email').touched)"
                                                                    class="text-danger">
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('email').hasError('required')">
                                                                        *Email is required.</span>
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('email').hasError('pattern')">
                                                                        *Please enter valid title.</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="form-group row align-items-center">
                                                            <label class="col-md-4"> Mobile Number
                                                                <span>:</span></label>
                                                            <div class="col-md-8">
                                                                <input type="text" class="form-control" maxlength="20"
                                                                    formControlName="mobile">
                                                                <div *ngIf="editStaticContentForm.get('mobile').invalid && (editStaticContentForm.get('mobile').dirty || editStaticContentForm.get('mobile').touched)"
                                                                    class="text-danger">
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('mobile').hasError('required')">
                                                                        *Mobile is required.</span>
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('mobile').hasError('pattern')">
                                                                        *Please enter valid title.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row align-items-center">
                                                            <label class="col-md-4"> Link
                                                                <span>:</span></label>
                                                            <div class="col-md-8">
                                                                <input type="text" class="form-control" maxlength="60"
                                                                    formControlName="link">
                                                                <div *ngIf="editStaticContentForm.get('link').invalid && (editStaticContentForm.get('link').dirty || editStaticContentForm.get('link').touched)"
                                                                    class="text-danger">
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('link').hasError('required')">
                                                                        *Link is required.</span>
                                                                    <span
                                                                        *ngIf="editStaticContentForm.get('link').hasError('pattern')">
                                                                        *Please enter valid title.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <ck-editor id="editornewData" formControlName="description"
                                                        name="editor1" language="en" [config]="config"></ck-editor>
                                                    <div *ngIf="editStaticContentForm.get('description').invalid && (editStaticContentForm.get('description').dirty || editStaticContentForm.get('description').touched)"
                                                        class="text-danger">
                                                        <span
                                                            *ngIf="editStaticContentForm.get('description').hasError('required')">
                                                            *Description is required.</span>
                                                    </div>
                                                    <div class="text-center mt75 mb40">
                                                        <button class="btn btn-large  max-WT-150 btn-theme"
                                                            routerLink="/static-content-list">Back</button>

                                                        <button class="btn btn-large  max-WT-150 btn-theme"
                                                            (click)="editStaticContent()"
                                                            [disabled]="editStaticContentForm.invalid">Update</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>