import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileData: any;
  
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.getProfile()
  }

  // get profile
  getProfile() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.profile, 1).subscribe((res: any) => {
      console.log("profile response ==>", res);
      if (res.code == 200) {
        this.profileData = res.data;
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  changePassword() {
    this.router.navigate(['change-password'])
  }

  editProfile() {
    this.router.navigate(['edit-profile'])
  }

}
