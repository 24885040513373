import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPassword: FormGroup;
  // userId:any;
  token:any;

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService, public router: Router) { }

  ngOnInit() {
    this.resetPassword = new FormGroup({
      "password": new FormControl('', ([Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/)])),
      "confirmPassword": new FormControl('', [Validators.required])
    });
    this.activatedroute.params.subscribe((res) => {
      console.log(res)
      this.token = res.id
    })
  }

  resetApi() {
    if (this.resetPassword.valid) {
      const data = {
        password: this.resetPassword.value.password,
        newPassword: this.resetPassword.value.password
      }
      this.commonService.showSpinner()
      this.commonService.putApi(`${ApiEndPoint.resetPassword + this.token}`, data, 0).subscribe((res: any) => {
        // console.log('reset password response ==>', res);
        if (res.code == 200) {
          this.commonService.hideSpinner();
          this.commonService.successToast(res.message)
          this.router.navigateByUrl('/login')
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.message)
        }
      })
    } else {
      // console.log('Email is required.');
      this.commonService.errorToast('Email is required.')
    }
  }

}
