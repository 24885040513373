import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/authenticate/login/login.component';
import { ForgotPasswordComponent } from './pages/authenticate/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/authenticate/reset-password/reset-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './guard/auth.guard';
import { UserListComponent } from './pages/user-management/user-list/user-list.component';
import { ViewUserComponent } from './pages/user-management/view-user/view-user.component';
import { ViewCategoryComponent } from './pages/category/view-category/view-category.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { AddCategoryComponent } from './pages/category/add-category/add-category.component';
import { EditCategoryComponent } from './pages/category/edit-category/edit-category.component';
import { ProductListComponent } from './pages/product-management/product-list/product-list.component';
import { AddProductComponent } from './pages/product-management/add-product/add-product.component';
import { ViewProductComponent } from './pages/product-management/view-product/view-product.component';
import { EditProductComponent } from './pages/product-management/edit-product/edit-product.component';
import { ViewContactUsComponent } from './pages/contact-us/view-contact-us/view-contact-us.component';
import { ContactUsListComponent } from './pages/contact-us/contact-us-list/contact-us-list.component';
import { TransactionListComponent } from './pages/transaction-management/transaction-list/transaction-list.component';
import { ViewTransactionComponent } from './pages/transaction-management/view-transaction/view-transaction.component';
import { EarnCoinListComponent } from './pages/earn-coin-management/earn-coin-list/earn-coin-list.component';
import { ViewEarnCoinComponent } from './pages/earn-coin-management/view-earn-coin/view-earn-coin.component';
import { AddEarnCoinComponent } from './pages/earn-coin-management/add-earn-coin/add-earn-coin.component';
import { EditEarnCoinComponent } from './pages/earn-coin-management/edit-earn-coin/edit-earn-coin.component';
import { GenieCoinListComponent } from './pages/genie-coin-management/genie-coin-list/genie-coin-list.component';
import { ViewGenieCoinComponent } from './pages/genie-coin-management/view-genie-coin/view-genie-coin.component';
import { EditGenieCoinComponent } from './pages/genie-coin-management/edit-genie-coin/edit-genie-coin.component';
import { AddGenieCoinComponent } from './pages/genie-coin-management/add-genie-coin/add-genie-coin.component';
import { StaticContentListComponent } from './pages/static-content/static-content-list/static-content-list.component';
import { EditStaticContentComponent } from './pages/static-content/edit-static-content/edit-static-content.component';
import { ProfileComponent } from './pages/setting/profile/profile.component';
import { EditProfileComponent } from './pages/setting/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './pages/setting/change-password/change-password.component';
import { ViewStaticContentComponent } from './pages/static-content/view-static-content/view-static-content.component';
import { StoreListComponent } from './pages/store-managemnt/store-list/store-list.component';
import { AddStoreProductComponent } from './pages/store-managemnt/add-store-product/add-store-product.component';
import { ViewStoreComponent } from './pages/store-managemnt/view-store/view-store.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  // my profile
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent },
  // dashboard
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  // user management
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'view-user', component: ViewUserComponent, canActivate: [AuthGuard] },
  // category management
  { path: 'category-list', component: CategoryListComponent, canActivate: [AuthGuard] },
  { path: 'add-category', component: AddCategoryComponent, canActivate: [AuthGuard] },
  { path: 'view-category', component: ViewCategoryComponent, canActivate: [AuthGuard] },
  { path: 'edit-category', component: EditCategoryComponent, canActivate: [AuthGuard] },
  // product management
  { path: 'product-list', component: ProductListComponent, canActivate: [AuthGuard] },
  { path: 'add-product', component: AddProductComponent, canActivate: [AuthGuard] },
  { path: 'view-product', component: ViewProductComponent, canActivate: [AuthGuard] },
  { path: 'edit-product', component: EditProductComponent, canActivate: [AuthGuard] },
  // contact management
  { path: 'contact-us-list', component: ContactUsListComponent, canActivate: [AuthGuard] },
  { path: 'view-contact-us', component: ViewContactUsComponent, canActivate: [AuthGuard] },
  // transaction management
  { path: 'transaction-list', component: TransactionListComponent, canActivate: [AuthGuard] },
  { path: 'view-transaction', component: ViewTransactionComponent, canActivate: [AuthGuard] },
  // earn coin management
  { path: 'earn-coin-list', component: EarnCoinListComponent, canActivate: [AuthGuard] },
  { path: 'add-earn-coin', component: AddEarnCoinComponent, canActivate: [AuthGuard] },
  { path: 'view-earn-coin', component: ViewEarnCoinComponent, canActivate: [AuthGuard] },
  { path: 'edit-earn-coin', component: EditEarnCoinComponent, canActivate: [AuthGuard] },
  // genie coin management
  { path: 'genie-coin-list', component: GenieCoinListComponent, canActivate: [AuthGuard] },
  { path: 'add-genie-coin', component: AddGenieCoinComponent, canActivate: [AuthGuard] },
  { path: 'view-genie-coin', component: ViewGenieCoinComponent, canActivate: [AuthGuard] },
  { path: 'edit-genie-coin', component: EditGenieCoinComponent, canActivate: [AuthGuard] },
  // static content management
  { path: 'static-content-list', component: StaticContentListComponent, canActivate: [AuthGuard] },
  { path: 'view-static-content', component: ViewStaticContentComponent, canActivate: [AuthGuard] },
  { path: 'edit-static-content', component: EditStaticContentComponent, canActivate: [AuthGuard] },
  // store management
  { path: 'store-product-list', component: StoreListComponent, canActivate: [AuthGuard] },
  { path: 'store-product', component: AddStoreProductComponent, canActivate: [AuthGuard]},
  { path: 'view-store', component: ViewStoreComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
