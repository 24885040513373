import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-edit-genie-coin',
  templateUrl: './edit-genie-coin.component.html',
  styleUrls: ['./edit-genie-coin.component.scss']
})
export class EditGenieCoinComponent implements OnInit {
  editGenieCoinForm: FormGroup;
  genieCoinId: any;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.genieCoinId = res.genieCoinId;
    })
    this.editGenieCoinFormValidation();
    this.viewGenieCoin()
  }

  editGenieCoinFormValidation() {
    this.editGenieCoinForm = new FormGroup({
      'price': new FormControl('', [Validators.required]),
      'currency': new FormControl('', [Validators.required]),
      'coin': new FormControl('', [Validators.required])
    })
  }

  // get genie coin detail by id
  viewGenieCoin() {
    let apiReqUrl: any = `${ApiEndPoint.viewGenieCoin + '?_id=' + this.genieCoinId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        // this.genieCoinDetails = res.data;
        this.editGenieCoinForm.patchValue({
          'price': res.data.price,
          'currency': res.data.currency,
          'coin': res.data.coin
        })
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  editGenieCoin() {
    let data = {
      "_id": this.genieCoinId,
      'price': this.editGenieCoinForm.value.price,
      'currency': this.editGenieCoinForm.value.currency,
      'coin': this.editGenieCoinForm.value.coin
    }
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editGenieCoin, data, 1).subscribe((res: any) => {
      console.log("edit genie coin response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['genie-coin-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}