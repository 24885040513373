import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
declare var $: any;

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  searchForm: FormGroup;
  categoryListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  categoryId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  // today: string
  today = new Date().toISOString().split('T')[0]

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getCategoryList();
    // this.commonService.BlockFuture()
    // this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      // status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getCategoryList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        // status: '',
        fromDate: '',
        toDate: ''
      });
      this.getCategoryList()
    }
  }

  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `${ApiEndPoint.categoryList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage) +
      (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
      (this.searchForm.value.fromDate ? ('&fromDate=' + new Date(this.searchForm.value.fromDate).toISOString()) : '') +
      (this.searchForm.value.toDate ? ('&toDate=' + new Date(this.searchForm.value.toDate).toISOString()) : '')
      }`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.code == 200) {
        this.categoryListArray = res.data.docs ? res.data.docs : '';
        this.totalItems = res.data.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.categoryListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    }, error => {
      this.categoryListArray = []
      this.totalItems = 0
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getCategoryList()
  }

  addCategory() {
    this.router.navigate(['/add-category'])
  }
  // view category
  viewCategory(categoryId) {
    this.router.navigate(['/view-category'], { queryParams: { categoryId: categoryId } })
  }
  // edit category
  editCategory(categoryId) {
    this.router.navigate(['/edit-category'], { queryParams: { categoryId: categoryId } })
  }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  deleteCategoryModal(categoryId) {
    $('#deleteCategory').modal('show')
    this.categoryId = categoryId
  }
  deleteCategory() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = `${ApiEndPoint.deleteCategory + '?_id=' + this.categoryId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("delete category user response ==>", res)
      $('#deleteCategory').modal('hide');
      if (res.code == 200) {
        this.getCategoryList()
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }



}
