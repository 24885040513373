import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-view-store',
  templateUrl: './view-store.component.html',
  styleUrls: ['./view-store.component.scss']
})
export class ViewStoreComponent implements OnInit {
  productId:any
  productDetails:any
  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.productId = res.productId;
    })
    this.viewProduct()
  }

    // get product detail by id
    viewProduct() {
      let apiReqUrl: any = `${ApiEndPoint.viewProduct + '?_id=' + this.productId}`
      this.commonService.showSpinner();
      this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
        if (res.code == 200) {
          this.productDetails = res.data;
          this.commonService.hideSpinner();
          this.commonService.successToast(res.message);
        } else {
          this.commonService.hideSpinner();
          this.commonService.errorToast(res.message)
        }
      })
    }
}
