<div class="login-wrapper">
    <div class="container-common">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="d-flex align-items-center minheight70vh">
                    <form class="login_box_outer w-100" [formGroup]="loginForm">
                        <div class="login-box max-WT-520">
                            <div class="login-right-block">
                                <div class="login-heading">
                                    <h4>LOGIN</h4>
                                </div>
                               
                                <div class="login-box-body">
                                    <div class="form-group">
                                        <input type="text" formControlName="email" class="form-control"
                                            maxlength="60" placeholder="Email"
                                            (keypress)="commonService.preventSpace($event)" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('email').invalid && (loginForm.get('email').dirty  || loginForm.get('email').touched)"
                                            style="color: red;text-align: start">
                                            <span *ngIf="loginForm.get('email').hasError('required')">*Email is
                                                required.</span>
                                       
                                        </div>
                                    </div>
                                    <div class="form-group eyeicon">
                                        <input type="password" maxlength="32" formControlName="password"
                                            class="form-control " placeholder="Password"
                                            (keypress)="commonService.preventSpace($event)" />
                                        <div class="text-danger"
                                            *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty  || loginForm.get('password').touched)"
                                            style="color: red;text-align: start">
                                            <span *ngIf="loginForm.get('password').hasError('required')">*Password is
                                                required.</span>
                                        </div>
                                    </div>
                                    <div class="d-flex">
                                        <label class="checkbox-design">
                                            <input type="checkbox" formControlName="rememberMe" /><span></span>Remember
                                            me
                                        </label>
                                        <span class="ml-auto forgot-links">
                                            <a [routerLink]="['/forgot-password']" class="link font17">Forgot
                                                Password?</a>
                                        </span>
                                    </div>

                                </div>
                                <div class="text-center mt40">
                                    <button class="btn btn-login btn-large width100 font-100"
                                        [disabled]="!loginForm.valid" (click)="login()">LOGIN</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>