import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-view-earn-coin',
  templateUrl: './view-earn-coin.component.html',
  styleUrls: ['./view-earn-coin.component.scss']
})
export class ViewEarnCoinComponent implements OnInit {
  earnCoinId: any;
  earnCoinDetails: any;

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.earnCoinId = res.earnCoinId;
    })
    this.viewEarnCoin()
  }

  // get earn coin detail by id
  viewEarnCoin() {
    let apiReqUrl: any = `${ApiEndPoint.viewEarnCoin + '?_id=' + this.earnCoinId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        this.earnCoinDetails = res.data;
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}


