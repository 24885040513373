import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  dashboardData: any = {
    user: 0,
    category: 0,
    product: 0,
    contactUs: 0,
    transaction: 0
  };

  constructor(public commonService: CommonService) { }

  ngOnInit() {
    // this.getDashboard();
    this.getUserList();
    this.getCategoryList();
    this.getProductList();
    this.getContactUsList();
    this.getTransationList();
  }

  // // get dashboard data
  // getDashboard() {
  //   this.commonService.showSpinner();
  //   this.commonService.getApi('', 1).subscribe((res: any) => {
  //     console.log("dashboard response ==>", res)
  //     if (res.code == 200) {
  //       this.dashboardData = res.result ? res.result : ''
  //       this.commonService.hideSpinner();
  //       this.commonService.successToast(res.message);
  //     } else if (res.code == 401) {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.message);
  //       this.commonService.logout();
  //     }
  //     else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.message)
  //     }
  //   })
  // }

  // -------------------- get user list --------------------- //
  getUserList() {
    let apiReqUrl: any = `${ApiEndPoint.userList
      }`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get user management list response ==>", res)
      if (res.code == 200) {
        // this.userListArray = res.data.docs ? res.data.docs : '';
        this.dashboardData.user = res.data.total
        // this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        // this.userListArray = []
        // this.totalItems = 0
        // this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }
  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `${ApiEndPoint.categoryList}`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.code == 200) {
        // this.categoryListArray = res.data.docs ? res.data.docs : '';
        this.dashboardData.category = res.data.total
        // this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        // this.categoryListArray = []
        // this.totalItems = 0
        // this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }
  // -------------------- get product list --------------------- //
  getProductList() {
    let apiReqUrl: any = `${ApiEndPoint.productList + ('?type=AUCTION')
      }`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get product management list response ==>", res)
      if (res.code == 200) {
        // this.productListArray = res.data.docs ? res.data.docs : '';
        this.dashboardData.product = res.data.total
        // this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        // this.productListArray = []
        // this.totalItems = 0
        // this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }

  // -------------------- get contact us list --------------------- //
  getContactUsList() {
    // let apiReqUrl: any = `${ApiEndPoint.contactUsList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage) +
    //   (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
    //   (this.searchForm.value.fromDate ? ('&fromDate=' + this.searchForm.value.fromDate) : '') +
    //   (this.searchForm.value.toDate ? ('&toDate=' + this.searchForm.value.toDate) : '')
    //   }`
    let apiReqUrl: any = `${ApiEndPoint.contactUsList}`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get contact us management list response ==>", res)
      if (res.code == 200) {
        // this.contactUsListArray = res.data ? res.data : '';
        this.dashboardData.contactUs = res.data.length;
        // this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        // this.contactUsListArray = []
        // this.totalItems = 0
        // this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }

  // -------------------- get transaction list --------------------- //
  getTransationList() {
    // let apiReqUrl: any = `${ApiEndPoint.transactionList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage) +
    //   (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
    //   (this.searchForm.value.fromDate ? ('&fromDate=' + this.searchForm.value.fromDate) : '') +
    //   (this.searchForm.value.toDate ? ('&toDate=' + this.searchForm.value.toDate) : '')
    //   }`
    let apiReqUrl: any = `${ApiEndPoint.transactionList}`
    // this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get transaction management list response ==>", res)
      if (res.code == 200) {
        // this.transactionListArray = res.data ? res.data : '';
        this.dashboardData.transaction = res.data.length
        // this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        // this.transactionListArray = []
        // this.totalItems = 0
        // this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }

}