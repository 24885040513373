<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Dashboard</h1>
        </div>
        <div class="content-section dashboard-block">
            <ul class="dash_list d-flex w-100 flex-wrap text-center">
                <li class="card-color" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-users" aria-hidden="true"></i>
                    </div>
                    <h2>TOTAL USERS</h2>
                    <h4>{{dashboardData?.user}}</h4>
                </li>
                <li class="card-color" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-list-alt" aria-hidden="true"></i>
                    </div>
                    <h2>TOTAL CATEGORY</h2>
                    <h4>{{dashboardData?.category}}</h4>
                </li>
                <li class="card-color" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-gavel" aria-hidden="true"></i>
                    </div>
                    <h2>TOTAL AUCTIONS</h2>
                    <h4>{{dashboardData?.product}}</h4>
                </li>
            </ul>
            <ul class="dash_list d-flex w-100 flex-wrap text-center">
                <li class="card-color" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-address-book" aria-hidden="true"></i>
                    </div>
                    <h2>TOTAL CONTACT US</h2>
                    <h4>{{dashboardData?.contactUs}}</h4>
                </li>
                <li class="card-color" style="cursor:pointer">
                    <div class="dash_icon">
                        <i class="fa fa-exchange" aria-hidden="true"></i>
                    </div>
                    <h2>TOTAL TRANSACTIONS</h2>
                    <h4>{{dashboardData?.transaction}}</h4>
                </li>
            </ul>
        </div>
    </main>
</div>