import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-add-genie-coin',
  templateUrl: './add-genie-coin.component.html',
  styleUrls: ['./add-genie-coin.component.scss']
})
export class AddGenieCoinComponent implements OnInit {
  addGenieCoinForm: FormGroup;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addGenieCoinFormValidation();
  }

  addGenieCoinFormValidation() {
    this.addGenieCoinForm = new FormGroup({
      'price': new FormControl('', [Validators.required]),
      'currency': new FormControl('', [Validators.required]),
      'coin': new FormControl('', [Validators.required])
    })
  }

  addGenieCoin() {
    let data = {
      'price': this.addGenieCoinForm.value.price,
      'currency': this.addGenieCoinForm.value.currency,
      'coin': this.addGenieCoinForm.value.coin
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.addGenieCoin, data, 1).subscribe((res: any) => {
      console.log("add genie coin response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['genie-coin-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}