<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Category / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addCategoryForm" #f="ngForm">
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Category Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Category Name"
                                            formControlName="categoryName" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addCategoryForm.get('categoryName').hasError('required') && (addCategoryForm.get('categoryName').dirty || addCategoryForm.get('categoryName').touched)">
                                                *Please enter category name.
                                            </span>
                                            <span
                                                *ngIf="addCategoryForm.get('categoryName').hasError('pattern') && (addCategoryForm.get('categoryName').dirty || f.submitted)">
                                                *CategoryName accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="addCategoryForm.get('categoryName').hasError('minlength') && !(addCategoryForm.get('categoryName').hasError('pattern')) && (addCategoryForm.get('categoryName').dirty || f.submitted)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="addCategoryForm.get('categoryName').hasError('maxlength') && !(addCategoryForm.get('categoryName').hasError('pattern')) && (addCategoryForm.get('categoryName').dirty || f.submitted)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Category Image <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">

                                        <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addCategoryForm.valid"
                            (click)="addCategory()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>