import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../provider/common.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor( public commonService: CommonService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          this.commonService.hideSpinner();
          // console.log(error)
          if (error.error instanceof ErrorEvent) {
            // console.log('This is client side error.')
            errorMsg = `Error: ${error.error.message}`
          } else {
            // console.log('This is server side error')
            errorMsg = `Error Code: ${error.status}, Message: ${error.message}`
            switch (error.error.code) {
              case 400:
                console.log('Error 400.')
                this.commonService.errorToast(error.error.message);
                // this.commonService.logout();
                break;
              case 401:
                // this.commonService.errorToast('Unauthorized Access.');
                this.commonService.errorToast(error.error.message);
                this.commonService.logout()
                break;
              case 403:
                console.log("Error 403")
                // this.commonService.errorToast('Unauthorized Access.');
                this.commonService.errorToast(error.error.message);
                this.commonService.logout()
                break;
              case 500:
                this.commonService.errorToast('Internal Server Error.');
                break;
              default:
                this.commonService.errorToast(error.error.message);
                break;
            }
          }
          // return throwError(errorMsg)
          return throwError(error)
        })
      )
  }
}