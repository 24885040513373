<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Static Content / View</h1>
        </div>
        <div class="content-section">
            <!-- <div class="order-view  max-WT-700 mrgn-0-auto setting-page"> -->
            <div class="order-view   mrgn-0-auto setting-page">
                <div class="mb40">
                    <!-- <div class="admin-profile">
                        <h4 class="mb20 text-center">View Profile</h4>
                    </div> -->
                    <!-- <div class="add-store-block input-style view-label-align"> -->
                    <div class=" input-style view-label-align">
                        <!-- <div class="form-group row align-items-baseline">
                            <div class="col-md-12" style="text-align: center">
                                <img src="{{staticContentDetails?.profilePic || 'assets/img/profile-img.jpg' }}"
                                    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;"
                                    alt="profile image">
                            </div>
                        </div> -->
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-2 textalignright ">Title <span>:</span></label>
                            <div class="col-md-10 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{staticContentDetails?.title || '--'}}</p>
                                </div>
                            </div>
                        </div>

                        <!-- show only when static content type contactUS -->
                        <ng-container *ngIf="staticContentType == 'contactUs'">
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-2 textalignright ">Email <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <p style="padding: 0px 5px!important;">
                                            {{staticContentDetails?.email || '--'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-2 textalignright ">Mobile Number <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <p style="padding: 0px 5px!important;">
                                            {{staticContentDetails?.mobile || '--'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-2 textalignright ">Link <span>:</span></label>
                                <div class="col-md-10 textaligncenter">
                                    <div class="form-group">
                                        <p style="padding: 0px 5px!important;">
                                            {{staticContentDetails?.link || '--'}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- end show only when static content type contactUS -->

                        <div class="form-group row align-items-baseline">
                            <label class="col-md-2 textalignright">Description <span>:</span></label>
                            <div class="col-md-10 textaligncenter">
                                <div class="form-group ">
                                    <p style="padding: 0px 5px!important;">
                                        {{staticContentDetails?.description || '--'}}</p>

                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-2 textalignright">Created At <span>:</span></label>
                            <div class="col-md-10 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{(staticContentDetails?.createdAt | date:'medium' )|| '--'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme"
                            routerLink="/static-content-list">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>