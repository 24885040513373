import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-add-static-content',
  templateUrl: './add-static-content.component.html',
  styleUrls: ['./add-static-content.component.scss']
})
export class AddStaticContentComponent implements OnInit {
  addProductForm: FormGroup
  imageUrl
  categoryListArray: any[] = [];

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addProductFormValidation();
    this.getCategoryList()
  }
  addProductFormValidation() {
    this.addProductForm = new FormGroup({
      'categoryId': new FormControl('', [Validators.required]),
      'auctionName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'price': new FormControl('', [Validators.required, Validators.maxLength(16)]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('', [Validators.required]),
      'totalEntries': new FormControl('', [Validators.required, Validators.maxLength(16)]),
    })
  }

  // -------------------- get category list --------------------- //
  getCategoryList() {
    let apiReqUrl: any = `${ApiEndPoint.categoryList}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.code == 200) {
        this.categoryListArray = res.data.docs ? res.data.docs : '';
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        this.categoryListArray = []
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }

  addProduct() {
    let data = {
      'categoryId': this.addProductForm.value.categoryId,
      'auctionName': this.addProductForm.value.auctionName,
      'price': Number(this.addProductForm.value.price),
      'description': this.addProductForm.value.description,
      'image': this.imageUrl,
      'totalEntries': Number(this.addProductForm.value.totalEntries)
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.addProduct, data, 1).subscribe((res: any) => {
      console.log("add product response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['product-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
