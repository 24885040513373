import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-view-genie-coin',
  templateUrl: './view-genie-coin.component.html',
  styleUrls: ['./view-genie-coin.component.scss']
})
export class ViewGenieCoinComponent implements OnInit {
  genieCoinId: any;
  genieCoinDetails: any;

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.genieCoinId = res.genieCoinId;
    })
    this.viewGenieCoin()
  }

  // get genie coin detail by id
  viewGenieCoin() {
    let apiReqUrl: any = `${ApiEndPoint.viewGenieCoin + '?_id=' + this.genieCoinId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        this.genieCoinDetails = res.data;
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
