import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgOtpInputModule } from 'ng-otp-input';
import { CKEditorModule } from 'ngx-ckeditor';

import { AppComponent } from './app.component';
import { HeaderComponent } from './pages/sharing/header/header.component';
import { SidebarComponent } from './pages/sharing/sidebar/sidebar.component';
import { FooterComponent } from './pages/sharing/footer/footer.component';
import { LoginComponent } from './pages/authenticate/login/login.component';
import { ForgotPasswordComponent } from './pages/authenticate/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/authenticate/reset-password/reset-password.component';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { AddCategoryComponent } from './pages/category/add-category/add-category.component';
import { EditCategoryComponent } from './pages/category/edit-category/edit-category.component';
import { ViewCategoryComponent } from './pages/category/view-category/view-category.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/setting/profile/profile.component';
import { EditProfileComponent } from './pages/setting/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './pages/setting/change-password/change-password.component';
import { UserListComponent } from './pages/user-management/user-list/user-list.component';
import { ViewUserComponent } from './pages/user-management/view-user/view-user.component';
import { ProductListComponent } from './pages/product-management/product-list/product-list.component';
import { AddProductComponent } from './pages/product-management/add-product/add-product.component';
import { EditProductComponent } from './pages/product-management/edit-product/edit-product.component';
import { ViewProductComponent } from './pages/product-management/view-product/view-product.component';
import { StaticContentListComponent } from './pages/static-content/static-content-list/static-content-list.component';
import { AddStaticContentComponent } from './pages/static-content/add-static-content/add-static-content.component';
import { EditStaticContentComponent } from './pages/static-content/edit-static-content/edit-static-content.component';
import { ViewStaticContentComponent } from './pages/static-content/view-static-content/view-static-content.component';
import { ContactUsListComponent } from './pages/contact-us/contact-us-list/contact-us-list.component';
import { ViewContactUsComponent } from './pages/contact-us/view-contact-us/view-contact-us.component';
import { TransactionListComponent } from './pages/transaction-management/transaction-list/transaction-list.component';
import { ViewTransactionComponent } from './pages/transaction-management/view-transaction/view-transaction.component';
import { EarnCoinListComponent } from './pages/earn-coin-management/earn-coin-list/earn-coin-list.component';
import { AddEarnCoinComponent } from './pages/earn-coin-management/add-earn-coin/add-earn-coin.component';
import { ViewEarnCoinComponent } from './pages/earn-coin-management/view-earn-coin/view-earn-coin.component';
import { EditEarnCoinComponent } from './pages/earn-coin-management/edit-earn-coin/edit-earn-coin.component';
import { GenieCoinListComponent } from './pages/genie-coin-management/genie-coin-list/genie-coin-list.component';
import { AddGenieCoinComponent } from './pages/genie-coin-management/add-genie-coin/add-genie-coin.component';
import { ViewGenieCoinComponent } from './pages/genie-coin-management/view-genie-coin/view-genie-coin.component';
import { EditGenieCoinComponent } from './pages/genie-coin-management/edit-genie-coin/edit-genie-coin.component';
import { StoreListComponent } from './pages/store-managemnt/store-list/store-list.component';
import { AddStoreProductComponent } from './pages/store-managemnt/add-store-product/add-store-product.component';
import { ViewStoreComponent } from './pages/store-managemnt/view-store/view-store.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CategoryListComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    ViewCategoryComponent,
    DashboardComponent,
    ProfileComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    UserListComponent,
    ViewUserComponent,
    ProductListComponent,
    AddProductComponent,
    EditProductComponent,
    ViewProductComponent,
    StaticContentListComponent,
    AddStaticContentComponent,
    EditStaticContentComponent,
    ViewStaticContentComponent,
    ContactUsListComponent,
    ViewContactUsComponent,
    TransactionListComponent,
    ViewTransactionComponent,
    EarnCoinListComponent,
    AddEarnCoinComponent,
    ViewEarnCoinComponent,
    EditEarnCoinComponent,
    GenieCoinListComponent,
    AddGenieCoinComponent,
    ViewGenieCoinComponent,
    EditGenieCoinComponent,
    StoreListComponent,
    AddStoreProductComponent,
    ViewStoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      maxOpened: 1,
      preventDuplicates: true
    }),
    NgxPaginationModule,
    NgOtpInputModule,
    CKEditorModule,

  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
