<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Store / {{type | titlecase}}</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40" *ngIf="type== 'add' || 'edit'">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="editProductForm" #f="ngForm">
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Category Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <select class="form-control " formControlName="categoryId">
                                            <option value="" selected>Select Category</option>
                                            <option *ngFor="let item of categoryListArray" [value]="item._id">{{item?.categoryName}}</option>
                                        </select>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProductForm.get('categoryId').hasError('required') && (editProductForm.get('categoryId').dirty || editProductForm.get('categoryId').touched)">
                                                *Please select category name.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Product Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Product Name"
                                            formControlName="auctionName" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProductForm.get('auctionName').hasError('required') && (editProductForm.get('auctionName').dirty || editProductForm.get('auctionName').touched)">
                                                *Please enter product name.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('auctionName').hasError('pattern') && (editProductForm.get('auctionName').dirty)">
                                                *Product name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('auctionName').hasError('minlength') && !(editProductForm.get('auctionName').hasError('pattern')) && (editProductForm.get('auctionName').dirty)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('auctionName').hasError('maxlength') && !(editProductForm.get('auctionName').hasError('pattern')) && (editProductForm.get('auctionName').dirty)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Price <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Price"
                                            formControlName="price"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProductForm.get('price').hasError('required') && (editProductForm.get('price').dirty || editProductForm.get('price').touched)">
                                                *Please enter price.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Description <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Description"
                                            formControlName="description" (keypress)="commonService.preventSpace($event)"
                                            maxlength="256">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProductForm.get('description').hasError('required') && (editProductForm.get('description').dirty || editProductForm.get('description').touched)">
                                                *Please enter description.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('description').hasError('pattern') && (editProductForm.get('description').dirty)">
                                                *Description name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('description').hasError('minlength') && !(editProductForm.get('description').hasError('pattern')) && (editProductForm.get('description').dirty)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="editProductForm.get('description').hasError('maxlength') && !(editProductForm.get('description').hasError('pattern')) && (editProductForm.get('description').dirty)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Product Image <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file"  name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">

                                        <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/store-product-list">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="editProductForm.invalid" (click)=" type=='edit' ? editProduct() : addProduct() " >Submit</button>
                    </div>
                </div>

            </div>
        </div>
    </main>
</div>