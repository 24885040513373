import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';
declare var $: any;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  searchForm: FormGroup;
  userListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  userId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  // today: string
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getUserList();
    // this.commonService.BlockFuture()
    // this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getUserList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getUserList()
    }
  }
  selectStatus() {
    this.currentPage = 1
  }
  
  // -------------------- get user list --------------------- //
  getUserList() {
    let apiReqUrl: any = `${ApiEndPoint.userList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage) +
      (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
      (this.searchForm.value.status ? ('&status=' + this.searchForm.value.status) : '') +
      (this.searchForm.value.fromDate ? ('&fromDate=' + new Date(this.searchForm.value.fromDate).toISOString()) : '') +
      (this.searchForm.value.toDate ? ('&toDate=' + new Date(this.searchForm.value.toDate).toISOString()) : '')
      }`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get user management list response ==>", res)
      if (res.code == 200) {
        this.userListArray = res.data.docs ? res.data.docs : '';
        this.totalItems = res.data.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.userListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getUserList()
  }

  // view user
  viewUser(userId) {
    this.router.navigate(['/view-user'], { queryParams: { userId: userId } })
  }

  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  blockUnblockUserModal(userId, userStatus) {
    this.userStatus = userStatus
    $('#blockUnblockUser').modal('show')
    this.userId = userId
  }
  blockUnblockUser() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = `${ApiEndPoint.blockUnblockUser + '?_id=' + this.userId}`
    this.commonService.showSpinner();
    this.commonService.putApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("blockUnblockUser user response ==>", res)
      $('#blockUnblockUser').modal('hide');
      if (res.code == 200) {
        this.getUserList()
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }







}
