import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {
  editCategoryForm: FormGroup
  imageUrl: any;
  // categoryDetails: any;
  categoryId: any;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.categoryId = res.categoryId;
    })
    this.viewCategory()
    this.addCategoryFormValidation();
  }
  addCategoryFormValidation() {
    this.editCategoryForm = new FormGroup({
      'categoryName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      // 'image': new FormControl('', [Validators.required]),
    })
  }

  // get category detail by id
  viewCategory() {
    let apiReqUrl: any = `${ApiEndPoint.viewCategory + '?_id=' + this.categoryId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        // this.categoryDetails = res.data;
        this.editCategoryForm.patchValue({
          categoryName: res.data.categoryName
        })
        this.imageUrl = res.data.image
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  editCategory() {
    if (!this.imageUrl) {
      return this.commonService.infoToast('Please upload category image.')
    }
    let data = {
      '_id': this.categoryId,
      'categoryName': this.editCategoryForm.value.categoryName,
      'image': this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editCategory, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['category-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }


  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.imageUrl = res['data']['secure_url'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }
}