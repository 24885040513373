import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';
declare var $: any;

@Component({
  selector: 'app-contact-us-list',
  templateUrl: './contact-us-list.component.html',
  styleUrls: ['./contact-us-list.component.scss']
})
export class ContactUsListComponent implements OnInit {

  searchForm: FormGroup;
  contactUsListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  contactUsId: any;

  // hospitalIds: any = [];
  // // checked: any
  // isCheckedAll: any = false;
  // checked: any
  // toDate: string | number | Date;
  // newDate: string | number | Date;
  // oldDate: string | number | Date;
  // fromDate: string | number | Date;
  today: string

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.searchFormValidation();
    // this.searchForm.get('fromDate').valueChanges.subscribe((x)=>{
    //   console.log('x',x);
    //   this.fromDate = x
    // })
    this.getContactUsList();
    this.commonService.BlockFuture()
    this.today = new Date().toISOString()
    console.log(this.today)
  }

  // Search by Date
  //    searchByDate(){

  //  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      // status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      //   if(this.searchForm.value.fromDate > this.searchForm.value.toDate){
      //     this.commonService.errorToast('To date should be greater than From date')
      //     // return
      //     // this.searchForm.value.fromDate = '';
      //     // this.messege = "please enter right date"
      //   }
      //   else if(this.searchForm.value.fromDate <= this.searchForm.value.toDate){

      //  this.oldDate = new Date(this.searchForm.value.fromDate).toISOString()
      //  this.newDate = new Date(this.searchForm.value.toDate+'T23:59:59.999Z')
      //  console.log('this.oldDate',this.oldDate );
      //  console.log('this.newDate',this.newDate );
      //       }
      // this.fromDate =this.searchForm.value.fromDate
      this.getContactUsList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        // status: '',
        fromDate: '',
        toDate: ''
      });
      this.getContactUsList()
    }
  }

  // -------------------- get contact us list --------------------- //
  getContactUsList() {
    // let apiReqUrl: any = `${ApiEndPoint.contactUsList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage) +
    //   (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
    //   (this.searchForm.value.fromDate ? ('&fromDate=' + this.searchForm.value.fromDate) : '') +
    //   (this.searchForm.value.toDate ? ('&toDate=' + this.searchForm.value.toDate) : '')
    //   }`
    let apiReqUrl: any = `${ApiEndPoint.contactUsList + ('?page=' + this.currentPage) + ('&limit=' + this.itemPerPage)}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get contact us management list response ==>", res)
      if (res.code == 200) {
        this.contactUsListArray = res.data ? res.data : '';
        this.totalItems = res.data.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.contactUsListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getContactUsList()
  }

  // addContactUs() {
  //   this.router.navigate(['/add-contact-us'])
  // }
  // view contact us
  viewContactUs(contactUsId) {
    this.router.navigate(['/view-contact-us'], { queryParams: { contactUsId: contactUsId } })
  }
  // edit contact us
  // editContactUs(contactUsId) {
  //   this.router.navigate(['/edit-contact-us'], { queryParams: { contactUsId: contactUsId } })
  // }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete hospital ----------------------------- //
  deleteContactUsModal(contactUsId) {
    $('#deleteContactUs').modal('show')
    this.contactUsId = contactUsId
  }
  deleteContactUs() {
    // let data = {
    //   hospitalId: this.hospitalId
    // }
    // console.log(data)
    let apiReqUrl: any = `${ApiEndPoint.deleteContactUs + '?_id=' + this.contactUsId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("delete contact us user response ==>", res)
      $('#deleteContactUs').modal('hide');
      if (res.code == 200) {
        this.getContactUsList()
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
