import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-view-static-content',
  templateUrl: './view-static-content.component.html',
  styleUrls: ['./view-static-content.component.scss']
})
export class ViewStaticContentComponent implements OnInit {
  staticContentId: any;
  staticContentType: any;
  staticContentDetails: any;

  constructor(private activatedroute: ActivatedRoute, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.staticContentId = res.staticContentId;
      this.staticContentType = res.staticContentType
    })
    this.viewStaticContent()
  }

  // get static content detail by id
  viewStaticContent() {
    let apiReqUrl: any = `${ApiEndPoint.viewStaticContent + '?type=' + this.staticContentType}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        this.staticContentDetails = res.data;
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}


