<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Earn Coin / Edit</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="editEarnCoinForm" #f="ngForm">
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Title <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Title"
                                            formControlName="title" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editEarnCoinForm.get('title').hasError('required') && (editEarnCoinForm.get('title').dirty || editEarnCoinForm.get('title').touched)">
                                                *Please enter title.
                                            </span>
                                            <span
                                                *ngIf="editEarnCoinForm.get('title').hasError('pattern') && (editEarnCoinForm.get('title').dirty)">
                                                *Title accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="editEarnCoinForm.get('title').hasError('minlength') && !(editEarnCoinForm.get('title').hasError('pattern')) && (editEarnCoinForm.get('title').dirty)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="editEarnCoinForm.get('title').hasError('maxlength') && !(editEarnCoinForm.get('title').hasError('pattern')) && (editEarnCoinForm.get('title').dirty)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Coin <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Coin"
                                            formControlName="coin"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editEarnCoinForm.get('coin').hasError('required') && (editEarnCoinForm.get('coin').dirty || editEarnCoinForm.get('coin').touched)">
                                                *Please enter coin.
                                            </span>
                                       
                                            <!-- <span
                                                *ngIf="editEarnCoinForm.get('coin').hasError('maxlength') && !(editEarnCoinForm.get('coin').hasError('pattern')) && editEarnCoinForm.get('coin').dirty ">
                                                *Maximum length should not more than 18.
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/earn-coin-list">Back</button>

                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!editEarnCoinForm.valid"
                            (click)="editEarnCoin()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>