import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-add-earn-coin',
  templateUrl: './add-earn-coin.component.html',
  styleUrls: ['./add-earn-coin.component.scss']
})
export class AddEarnCoinComponent implements OnInit {
  addEarnCoinForm: FormGroup;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addEarnCoinFormValidation();
  }

  addEarnCoinFormValidation() {
    this.addEarnCoinForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'coin': new FormControl('', [Validators.required]),
    })
  }

  addEarnCoin() {
    let data = {
      'title': this.addEarnCoinForm.value.title,
      'coin': this.addEarnCoinForm.value.coin,
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.addEarnCoin, data, 1).subscribe((res: any) => {
      console.log("add earn coin response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['earn-coin-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
