<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">User Management / View</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="mb40">
                    <!-- <div class="admin-profile">
                        <h4 class="mb20 text-center">View Profile</h4>
                    </div> -->
                    <div class="add-store-block input-style view-label-align">
                        <div class="form-group row align-items-baseline">
                            <div class="col-md-12" style="text-align: center">
                                <img src="{{userDetails?.profilePic || 'assets/img/profile-img.jpg' }}"
                                    style="height: 100px;width: 100px; border-radius: 50%; border: 1px solid black!important;"
                                    alt="profile image">
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Name <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{userDetails?.name || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Email ID<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{userDetails?.email || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Mobile Number <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{userDetails?.mobileNumber || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Genie Coins <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{userDetails?.genieCoins || 0}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Registered Date <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{(userDetails?.createdAt | date:'medium' )|| '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Status <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{(userDetails?.status | titlecase)|| '--'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/user-list">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>