import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
declare var $: any;

@Component({
  selector: 'app-static-content-list',
  templateUrl: './static-content-list.component.html',
  styleUrls: ['./static-content-list.component.scss']
})
export class StaticContentListComponent implements OnInit {
  staticContentListArray: any = [];
  staticContentId: any;

  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.getStaticContentData()
  }

  // get static content data
  getStaticContentData() {
    this.commonService.showSpinner();
    this.commonService.getApi(ApiEndPoint.staticContentList, 1).subscribe((res: any) => {
      console.log("get static content list response ==>", res)
      if (res.code == 200) {
        this.staticContentListArray = res.data ? res.data : []
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.staticContentListArray = []
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  // addStaticContent() {
  //   this.router.navigate(['add-static-content'])
  // }
  // edit static content data
  editStaticContent(staticContentId, staticContentType) {
    this.router.navigate(['edit-static-content'], { queryParams: { staticContentId: staticContentId, staticContentType: staticContentType } })
  }
  // view static content data
  viewStaticContent(staticContentId, staticContentType) {
    this.router.navigate(['view-static-content'], { queryParams: { staticContentId: staticContentId, staticContentType: staticContentType } })
  }


  // ------------------------------- delete product ----------------------------- //
  // deleteStaticContentModal(staticContentId) {
  //   $('#deleteStaticContent').modal('show')
  //   this.staticContentId = staticContentId
  // }
  // deleteStaticContent() {
  //   // let data = {
  //   //   hospitalId: this.hospitalId
  //   // }
  //   // console.log(data)
  //   let apiReqUrl: any = `${ApiEndPoint.deleteStaticContent + '?_id=' + this.staticContentId}`
  //   this.commonService.showSpinner();
  //   this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
  //     console.log("delete static content response ==>", res)
  //     $('#deleteStaticContent').modal('hide');
  //     if (res.code == 200) {
  //       this.getStaticContentData()
  //       this.commonService.successToast(res.message);
  //     } else {
  //       this.commonService.hideSpinner();
  //       this.commonService.errorToast(res.message)
  //     }
  //   })
  // }


}
