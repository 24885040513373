<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Store Management / View</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="mb40">
                    <div class="add-store-block input-style view-label-align">
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Product Name <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{productDetails?.auctionName || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Product Image<span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <img src="{{productDetails?.image || '--'}}" alt="">

                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Price <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{productDetails?.price || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright ">Description <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group">
                                    <p style="padding: 0px 5px!important;">
                                        {{productDetails?.description || '--'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row align-items-baseline">
                            <label class="col-md-4 textalignright">Created At <span>:</span></label>
                            <div class="col-md-8 textaligncenter">
                                <div class="form-group ">
                                    <p class="content-view">
                                        {{(productDetails?.createdAt | date:'medium' )|| '--'}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/store-product-list">Back</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>