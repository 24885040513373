import { Component, OnInit } from '@angular/core';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/provider/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit {
  addCategoryForm: FormGroup
  imageUrl
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.addCategoryFormValidation();
  }
  addCategoryFormValidation() {
    this.addCategoryForm = new FormGroup({
      'categoryName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'image': new FormControl('', [Validators.required]),
    })
  }

  addCategory() {
    let data = {
      'categoryName': this.addCategoryForm.value.categoryName,
      'image': this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.addCategory, data, 1).subscribe((res: any) => {
      console.log("add category response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['category-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.imageUrl = res['data']['secure_url'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

}
