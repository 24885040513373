import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

@Component({
  selector: 'app-add-store-product',
  templateUrl: './add-store-product.component.html',
  styleUrls: ['./add-store-product.component.scss']
})
export class AddStoreProductComponent implements OnInit {
  editProductForm: FormGroup
  categoryListArray: any[] = [];
  productId: any;
  type : any;
  productDetails:any;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { 
    this.activatedroute.queryParams.subscribe((res) => {
      this.productId = res.productId?res.productId:'';
      this.type = res.type ? res.type :'';
      console.log(res,this.productId,this.type);
    })
    
  }

  ngOnInit(): void {
    this.getCategoryList()
    this.editProductForm = new FormGroup({
      'categoryId': new FormControl('', [Validators.required]),
      'auctionName': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'price': new FormControl('', [Validators.required, Validators.maxLength(16)]),
      'description': new FormControl('', [Validators.required]),
      // 'image': new FormControl('', [Validators.required]),
      // 'totalEntries': new FormControl('', [Validators.required, Validators.maxLength(16)]),
    })
    this._viewProduct()
  }

   // -------------------- get category list --------------------- //
   getCategoryList() {
    let apiReqUrl: any = `${ApiEndPoint.categoryList}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get category management list response ==>", res)
      if (res.code == 200) {
        this.categoryListArray = res.data.docs ? res.data.docs : '';
        this.commonService.hideSpinner();
        // this.commonService.successToast(res.message);
      } else {
        this.categoryListArray = []
        this.commonService.hideSpinner();
        // this.commonService.errorToast(res.message)
      }
    })
  }


  // view product if type is not add
  _viewProduct(){
    if (this.type == 'edit'){
      this.viewProduct()
    }
  } 

  // get product detail by id
  viewProduct() {
    let apiReqUrl: any = `${ApiEndPoint.viewProduct + '?_id=' + this.productId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        this.productDetails = res.data;
        this.imageUrl = res.data.image
        if(this.type=='edit'){
          this.editProductForm.patchValue({
            'categoryId': res.data.categoryId._id,
            'auctionName': res.data.auctionName,
            'price': res.data.price,
            'description': res.data.description,
            // 'totalEntries': res.data.totalEntries
          })
        }
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  editProduct() {
    let data = {
      '_id': this.productId,
      'categoryId': this.editProductForm.value.categoryId,
      'auctionName': this.editProductForm.value.auctionName,
      'price': Number(this.editProductForm.value.price),
      'description': this.editProductForm.value.description,
      'image': this.imageUrl,
      // 'totalEntries': Number(this.editProductForm.value.totalEntries)
    }
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editProduct, data, 1).subscribe((res: any) => {
      console.log("add product response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['/store-product-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  imageUrl:any='';
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.imageUrl = res['data']['secure_url'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  addProduct() {
  if (this.imageUrl!=''){
    let data = {
      'categoryId': this.editProductForm.value.categoryId,
      'auctionName': this.editProductForm.value.auctionName,
      'price': Number(this.editProductForm.value.price),
      'description': this.editProductForm.value.description,
      'image': this.imageUrl,
      "type": "STORE",
    }
    this.commonService.showSpinner();
    this.commonService.postApi(ApiEndPoint.addProduct, data, 1).subscribe((res: any) => {
      console.log("add product response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['/store-product-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }
  else{
    this.commonService.infoToast("Please select image.")
  }
 }

}
