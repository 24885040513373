export const ApiEndPoint = {
  login: 'admin/adminlogin',
  forgotPassword: 'admin/forgotPassword',
  resetPassword: 'admin/resetPassword/',

  // user
  userList: 'admin/listUser',
  viewUser: 'admin/viewUser',
  blockUnblockUser: 'admin/blockUnblockUser',

  // category
  categoryList: 'admin/listCategory',
  addCategory: 'admin/category',
  viewCategory: 'admin/category',
  editCategory: 'admin/category',
  deleteCategory: 'admin/category',

  // category
  productList: 'admin/listProduct',
  addProduct: 'admin/product',
  viewProduct: 'admin/product',
  editProduct: 'admin/product',
  deleteProduct: 'admin/product',

  // category
  contactUsList: 'admin/listContactUs',
  viewContactUs: 'admin/contactUs',
  deleteContactUs: 'admin/contactUs',

  // transaction
  transactionList: 'admin/listTransaction',
  viewTransacion: 'admin/transaction',

  // earn coin
  earnCoinList: 'admin/listEarncoin',
  addEarnCoin: 'admin/earncoin',
  viewEarnCoin: 'admin/earncoin',
  editEarnCoin: 'admin/earncoin',
  deleteEarnCoin: 'admin/earncoin',

  // genie coin
  genieCoinList: 'admin/listGenieCoin',
  addGenieCoin: 'admin/genieCoin',
  viewGenieCoin: 'admin/genieCoin',
  editGenieCoin: 'admin/genieCoin',
  deleteGenieCoin: 'admin/genieCoin',

  // static content
  staticContentList: 'static/listStaticContent',
  addStaticContent: 'static/listStaticContent',
  viewStaticContent: 'static/staticContent',
  editStaticContent: 'static/staticContent',
  deleteStaticContent: 'static/staticContent',

  // setting 
  changePassword: 'admin/changePassword',
  profile: 'admin/getProfile',
  editProfile: 'admin/updateAdminProfile',

  uploadFile: 'admin/uploadFile'
}
