import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-edit-static-content',
  templateUrl: './edit-static-content.component.html',
  styleUrls: ['./edit-static-content.component.scss']
})
export class EditStaticContentComponent implements OnInit {
  staticContentId: any;
  staticContentType: any;

  // result: any;
  config = {
    uiColor: '#F0F3F4',
    height: '100%'
  };
  editStaticContentForm: FormGroup;
  // editorValue;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.staticContentId = res.staticContentId;
      this.staticContentType = res.staticContentType
      // console.log('userId', this.userId, this.type, res);

    });
    this.editStaticContentForm = new FormGroup({
      "title": new FormControl('', ([Validators.required, Validators.minLength(3), Validators.pattern(/^[^\s][A-Za-z&\s]*$/)])),
      "email": new FormControl(''),
      "mobile": new FormControl(''),
      "link": new FormControl(''),
      "description": new FormControl('', ([Validators.required])),
    });
    this.viewStaticContent()
  }

  // get static content detail by id
  viewStaticContent() {
    let apiReqUrl: any = `${ApiEndPoint.viewStaticContent + '?type=' + this.staticContentType}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("view static content response=>", res)
      if (res.code == 200) {
        setTimeout(() => {
          this.editStaticContentForm.patchValue({
            title: res.data.title,

            // only for contact us
            email: res.data.email ? res.data.email : '',
            mobile: res.data.mobile ? res.data.mobile : '',
            link: res.data.link ? res.data.link : '',
            // end only for contact us

            description: res.data.description
          });
          this.commonService.hideSpinner();
        }, 1000);
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  editStaticContent() {
    const description = this.editStaticContentForm.value.description
    console.log(description)
    let data = {}
    if (this.staticContentType == 'contactUs') {
      data = {
        "_id": this.staticContentId,
        "title": this.editStaticContentForm.value.title,
        "description": description.slice(3, (description.length - 5)),
        "email": this.editStaticContentForm.value.email,
        "mobile": this.editStaticContentForm.value.mobile,
        "link": this.editStaticContentForm.value.link
      }
    } else {
      data = {
        "_id": this.staticContentId,
        "title": this.editStaticContentForm.value.title,
        "description": description.slice(3, (description.length - 5))
      }
    }
    console.log('data', data)
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editStaticContent, data, 1).subscribe((res: any) => {
      console.log("edit static content management response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['/static-content-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
