<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Static Content Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <!-- <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="admin-btn justify-content-end ">
                                            <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                (click)="addStaticContent()">Add</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <div class="tab-pane1">
                            <div class="table-responsive">
                                <table class="table table-bordered" aria-describedby="static content list">
                                    <thead>
                                        <tr class="no_wrap_th text-center">
                                            <th scope="col" class="Sorting-img"> Title </th>
                                            <!-- <th scope="col" class="Sorting-img">Description</th> -->
                                            <th scope="col" class="Sorting-img">Created At</th>
                                            <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngFor="let item of staticContentListArray">
                                            <td class="content">{{item?.title || '--'}}</td>
                                            <!-- <td class="content">
                                                <div [innerHTML]="item.description" class="description-content">
                                                </div>
                                            </td> -->
                                            <td class="content">{{item?.createdAt | date:'medium' || '--'}}</td>
                                            <td>
                                                <div class="justify-content-center d-flex">
                                                    <button class="btn btn-info ml-2 bt-margin" title="view"
                                                        (click)="viewStaticContent(item?._id, item.type)">
                                                        <i class="fa fa-eye" aria-hidden="true"
                                                            style="color:white"></i></button>
                                                    <button class="btn btn-info ml-2 bt-margin"
                                                        (click)="editStaticContent(item._id, item.type)"><i
                                                            class="fa fa-edit" aria-hidden="true"
                                                            style="color:white"></i></button>
                                                    <!-- <button class="btn btn-danger ml-2 bt-margin" title="delete"
                                                    (click)="deleteStaticContentModal(item?._id)"><i class="fa fa-trash"
                                                        aria-hidden="true" style="color:white"></i></button> -->
                                                </div>
                                            </td>
                                        </tr>
                                        <tr *ngIf="!staticContentListArray.length">
                                            <td class="table-no-record" colspan="3">No Record Found</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>


<!-- delete hospital modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="deleteStaticContent">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete Static Content?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this static content?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme"
                                        (click)="deleteStaticContent()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->
<!-- end delete hospital modal -->