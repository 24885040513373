<aside [hidden]="!showSidebar" class="sidebar" style="z-index: 7">
    <div class="adminprofile">
        <a class="navbar-brand logo" style="width: 100%; display: inline-flex; justify-content: center;">
            <span class="sidebar-profile-image-sec">

                <img class="lg-logo" [src]="profileData?.profilePic || 'assets/img/profile-img.jpg'" alt="image"
                    style=" border-radius: 50px; cursor: pointer;" (click)="adminProfile()">
            </span>
        </a>
        <div id="admin" class="profile-data-section" style="text-align: center; color: #343434;">
            <label style="cursor: pointer;" (click)="adminProfile()">{{profileData?.name || '--' }}</label>
            <p style="cursor: pointer;" (click)="adminProfile()">{{profileData?.email || '--' }}</p>
        </div>
    </div>

    <div class="sidebar-scroller">
        <div id="accordion" class="sidebar-menu">
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/dashboard' ? 'active' : ''"
                style="border-top: 1px solid #2835d4;">
                <div class="card-header clearfix" [routerLink]="['/dashboard']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-dashboard" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Dashboard</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/user-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/user-list']" id="headingThree" data-toggle="collapse"
                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-user" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">User Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/category-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/category-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-money" aria-hidden="true"></i> -->
                            <i class="fa fa-list-alt" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Category Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/product-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/product-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-briefcase" aria-hidden="true"></i> -->
                            <i class="fa fa-gavel" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Auction Management </span></a>
                </div>
            </div>

            <!-- store management -->
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/store-product-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/store-product-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-briefcase" aria-hidden="true"></i> -->
                            <i class="fa fa-gavel" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Store Management </span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/contact-us-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/contact-us-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">

                            <!-- <i class="fa fa-briefcase" aria-hidden="true"></i> -->
                            <i class="fa fa-address-book" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Contact Us Management </span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/transaction-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/transaction-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <!-- <img src="/assets/img/limit.png" alt=""> -->
                            
                            <i class="fa fa-exchange" aria-hidden="true"></i>

                        </span><span class="side_page_name head_color">Transaction Management</span></a>
                </div>
            </div>
            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/earn-coin-list' ? 'active' : ''">
                <div class="card-header clearfix" [routerLink]="['/earn-coin-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <!-- <i class="fa fa-percent" aria-hidden="true"></i> -->
                            <i class="fa fa-money" aria-hidden="true"></i>                            
                        </span><span
                            class="side_page_name head_color">Earn Coin Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/genie-coin-list' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/genie-coin-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon">
                            <!-- <i class="fa fa-image" aria-hidden="true"></i> -->
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </span><span
                            class="side_page_name head_color">Genie Coin Management</span></a>
                </div>
            </div>

            <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/static-content-list' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/static-content-list']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-file-text" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Static Content Management</span></a>
                </div>
            </div>

            <!-- <div class="menu-box toggle-menu" [ngClass]="currentUrl === '/change-password' ? 'active': ''">
                <div class="card-header clearfix" [routerLink]="['/change-password']" id="headingThree"
                    data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                    aria-controls="collapseThree">
                    <a class="side_menu">
                        <span class="side_menu_icon"><i class="fa fa-key" aria-hidden="true"></i></span><span
                            class="side_page_name head_color">Change Password</span></a>
                </div>
            </div> -->

            <div class="menu-box">
                <div class="card-header clearfix" id="headingOne">
                    <a class="side_menu" (click)="logOutModal()">
                        <span class="side_menu_icon">
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </span><span class="side_page_name head_color">Log Out</span>
                    </a>
                </div>
            </div>

        </div>
    </div>

</aside>




<!-- logout modal -->
<div class="modal fade global-modal reset-modal" id="logoutModal">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Logout?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to logout?</p>
                                <div class="modal-btn-box">
                                    <button type="button" class="btn btn-theme" (click)="logout()">YES</button>
                                    <button type="button" class="btn btn-theme ml-2" data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>