<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Transaction Management</h1>
        </div>
        <div class="content-section">
            <div class="outer-box">
                <div class="global-table no-radius p0">
                    <div class="tab-content1">
                        <!-- <div class="tab-pane1">
                            <div class="sec_head_new sec_head_new_after">
                                <form [formGroup]="searchForm">
                                    <div class="row">
                                        <div class="col-md-4  pr-0">
                                            <div class="filter_search mb20 width100">
                                                <div class="input-group filter_search_group">
                                                    <input type="text" class="form-control overflow-text"
                                                        formControlName="search" placeholder="Search">
                                                    <div class="input-group-append">
                                                        <button class="btn btn_search_group" type="button"><img
                                                                src="assets/img/icon-search.png" alt="Search"></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="date-input-box">
                                                <input type="date" name="" id="fromDate"
                                                    class="form-control date-input mr-2" formControlName="fromDate">
                                                <input type="date" name="" id="toDate" class="form-control date-input"
                                                    formControlName="toDate">
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="text-center admin-btn ">
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="searchFormSubmit()">Submit</button>
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="searchFormReset()">Reset</button>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="admin-btn justify-content-end ">
                                                <button type="button" class="btn  btn-theme ml-2 Go_btn"
                                                    (click)="addTransaction()">Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> -->
                        <div class="table-responsive">
                            <table class="table table-bordered" aria-describedby="hospital list">
                                <thead>
                                    <tr class="no_wrap_th text-center">
                                        <th scope="col" class="Sorting-img">S.No.</th>
                                        <th scope="col" class="Sorting-img">Amount</th>
                                        <th scope="col" class="Sorting-img">Currency</th>
                                        <th scope="col" class="Sorting-img">Customer Id</th>
                                        <th scope="col" class="Sorting-img">Genie Coin Id</th>
                                        <th scope="col" class="Sorting-img">Transaction Status</th>
                                        <th scope="col" class="Sorting-img">Type</th>
                                        <th scope="col" class="Sorting-img">Created At</th>
                                        <th scope="col" class="action_td_btn3 Sorting-img">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of transactionListArray | paginate: { itemsPerPage: itemPerPage, currentPage: currentPage, totalItems: totalItems}; let i = index"
                                        class="text-center">
                                        <td>
                                            {{itemPerPage * (currentPage-1)+ i+1}}</td>
                                        <td class="content">
                                            {{item?.amount || '--'}}</td>
                                        <td class="content">
                                            {{item?.currency || '--'}}</td>
                                        <td class="content">
                                            {{item?.customerId || '--'}}</td>
                                        <td class="content">
                                            {{item?.genieCoinId || '--'}}</td>
                                        <td class="content">
                                            {{item?.transactionStatus || '--'}}</td>
                                        <td class="content">
                                            {{item?.type || '--'}}</td>
                                        <td class="content">{{(item?.createdAt | date:'medium' )|| '--'}}</td>
                                        <td>
                                            <div class="justify-content-center d-flex">
                                                <button class="btn btn-info ml-2 bt-margin" title="view"
                                                    (click)="viewTransaction(item?._id)">
                                                    <i class="fa fa-eye" aria-hidden="true"
                                                        style="color:white"></i></button>
                                                <!-- <button class="btn btn-warning ml-2 bt-margin"
                                                    (click)="blockUnblockUserModal(item?._id)">
                                                    <i class="fa fa-ban" aria-hidden="true"
                                                        style="color:white"></i></button> -->
                                                <!-- <button class="btn btn-success ml-2 bt-margin"><i
                                                        class="fa fa-check-circle-o" aria-hidden="true"
                                                        style="color:white"></i></button> -->

                                                <!-- <button class="btn btn-info ml-2 bt-margin"
                                                    (click)="editTransaction(item?._id)"><i class="fa fa-edit"
                                                        aria-hidden="true" style="color:white"></i></button>
                                                <button class="btn btn-danger ml-2 bt-margin" title="delete"
                                                    (click)="deleteTransactionModal(item?._id)"><i class="fa fa-trash"
                                                        aria-hidden="true" style="color:white"></i></button> -->

                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="!transactionListArray.length">
                                        <td class="table-no-record" colspan="9">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="custom-pagination mt-2 text-align-end" *ngIf="totalItems > 10">
                                <pagination-controls (pageChange)="pagination($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<!-- delete hospital modal Start -->
<!-- <div class="modal fade global-modal reset-modal" id="deleteTransaction">
    <div class="modal-dialog max-WT-500">
        <form class="change_password">
            <div class="modal-content">
                <div class="modal-header d-block text-center modal-header-custm">
                    <h5 class="modal-title d-inline-block">Delete Transaction?</h5>
                </div>
                <div class="modal-inner-content">
                    <div class="modal-body">
                        <div class="text-center modal_flax_height d-flex align-items-center justify-content-center">
                            <div class="w-100">
                                <p>Are you sure you want to delete this transaction?</p>
                                <div class="modal-btn-box">
                                    <button type="submit" class="btn btn-theme"
                                        (click)="deleteTransation()">Yes</button>
                                    <button type="button" class="btn btn-danger btn-theme ml-2"
                                        data-dismiss="modal">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div> -->
<!-- end delete hospital modal -->
