<div class="login-wrapper">
    <div class="container-common">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="d-flex align-items-center minheight70vh">
            <form class="login_box_outer w-100" [formGroup]="resetPassword">
              <div class="login-box max-WT-520">
                <div class="login-right-block">
                  <div class="login-heading">
                    <h4>RESET PASSWORD</h4>
                  </div>
                  <div class="login-box-body">
                    <div class="form-group">
                      <input type="password" formControlName="password" class="form-control"
                        placeholder="Enter New Password" maxlength="56" (keypress)="commonService.preventSpace($event)" />
                      <div class="text-danger"
                        *ngIf="resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        *Please enter new password.
                      </div>
                      <div class="text-danger"
                        *ngIf="resetPassword.get('password').hasError('pattern') && !resetPassword.get('password').hasError('required') && (resetPassword.get('password').dirty || resetPassword.get('password').touched)">
                        *Password must contain atleast 8 characters including one digit and one special character.
                      </div>
                    </div>
                    <div class="form-group">
                      <input type="password" formControlName="confirmPassword" class="form-control"
                        placeholder="Enter Confirm Password" maxlength="56"
                        (keypress)="commonService.preventSpace($event)" />
                      <div class="text-danger"
                        *ngIf="(resetPassword.value.password != resetPassword.value.confirmPassword) && resetPassword.get('confirmPassword').dirty ">
                        *Password not matched.
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt40">
                    <button
                      [disabled]="resetPassword.invalid || (resetPassword.get('password').value != resetPassword.get('confirmPassword').value)"
                      (click)="resetApi()" routerLinkActive="router-link-active"
                      class="btn btn-login btn-large  width100">SUBMIT</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>