<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Profile / Edit</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="admin-profile">
                        <h4 class="mb20 text-center">Edit Profile</h4>
                    </div>
                    <div class="add-store-block input-style mb20 mt20 ">
                        <form [formGroup]="editProfileForm" #f="ngForm">
                            <div class="user-profile">
                                <div class="image-box">
                                    <img src="{{imageUrl || 'assets/img/profile-img.jpg'}}" id="sub-admin-image" alt="">
                                    <label class="upload-label">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)" maxlength="56">
                                        <em class="fa fa-pencil" style="color: #86559f!important;font-size: 12px"></em>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright ">Name <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Name"
                                            formControlName="name" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProfileForm.get('name').hasError('required') && (editProfileForm.get('name').dirty || editProfileForm.get('name').touched)">
                                                *Please enter Name.
                                            </span>
                                            <span
                                                *ngIf="editProfileForm.get('name').hasError('pattern') && (editProfileForm.get('name').dirty || f.submitted)">
                                                *Name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="editProfileForm.get('name').hasError('minlength') && !(editProfileForm.get('name').hasError('pattern')) && (editProfileForm.get('name').dirty || f.submitted)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="editProfileForm.get('name').hasError('maxlength') && !(editProfileForm.get('name').hasError('pattern')) && (editProfileForm.get('name').dirty || f.submitted)">
                                                *Maximum length should not more than 20.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Email <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Email Address"
                                            formControlName="email" (keypress)="commonService.preventSpace($event)"
                                            maxlength="50" disabled>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProfileForm.get('email').hasError('required') && (editProfileForm.get('email').dirty || editProfileForm.get('email').touched)">
                                                *Please enter you email.
                                            </span>
                                            <span *ngIf="editProfileForm.get('email').hasError('pattern')">
                                                *Please enter valid email.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline view-label-align">
                                <label class="col-md-4 textalignright">Mobile Number <span>:</span></label>
                                <div class="col-md-8 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Email Address"
                                            formControlName="mobile" (keypress)="commonService.preventSpace($event)"
                                            maxlength="20" disabled>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="editProfileForm.get('mobile').hasError('required') && (editProfileForm.get('mobile').dirty || editProfileForm.get('mobile').touched)">
                                                *Please enter you mobile.
                                            </span>
                                            <span *ngIf="editProfileForm.get('mobile').hasError('pattern')">
                                                *Please enter valid mobile.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" (click)="back()">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!editProfileForm.valid"
                            (click)="editProfile()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>