import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';
import { CommonService } from 'src/app/provider/common.service';

declare var $:any;

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {
  searchForm: FormGroup;
  today=new Date().toISOString().split('T')[0]
  productListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;

  productId: any;

  constructor(public commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.searchFormValidation()
    this.getStoreList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      'search': new FormControl(''),
      // status: new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getStoreList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        // status: '',
        fromDate: '',
        toDate: ''
      });
      this.getStoreList()
    }
  }

  // -------------------- get product list --------------------- //
  getStoreList() {
    let apiReqUrl: any = `${ApiEndPoint.productList + ('?type=STORE')  + ('&page=' + this.currentPage) + ('&limit=' + this.itemPerPage) + 
      (this.searchForm.value.search ? ('&search=' + this.searchForm.value.search) : '') +
      (this.searchForm.value.fromDate ? ('&fromDate=' + new Date(this.searchForm.value.fromDate).toISOString()) : '') +
      (this.searchForm.value.toDate ? ('&toDate=' + new Date(this.searchForm.value.toDate).toISOString()) : '')
      }`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      console.log("get product management list response ==>", res)
      if (res.code == 200) {
        this.productListArray = res.data.docs ? res.data.docs : '';
        this.totalItems = res.data.total
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.productListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    }, error=>{
      this.productListArray = []
      this.totalItems = 0
    })
  }

  pagination(event) {
    console.log(event)
    this.currentPage = event;
    this.getStoreList()
  }

  addProduct() {
    this.router.navigate(['/store-product'], { queryParams: { type: 'add' } })
  }
  // view product
  viewProduct(productId) {
    this.router.navigate(['/view-store'], { queryParams: { productId: productId} })
  }
  // edit product
  editProduct(productId) {
    this.router.navigate(['/store-product'], { queryParams: { productId: productId , type : 'edit'} })
  }
  // blockUnblockUserModal(){

  // }
  // ------------------------------- delete product ----------------------------- //
  deleteProductModal(productId) {
    $('#deleteProduct').modal('show')
    this.productId = productId
  }
  deleteProduct() {
    let apiReqUrl: any = `${ApiEndPoint.deleteProduct + '?_id=' + this.productId}`
    this.commonService.showSpinner();
    this.commonService.deleteApi(apiReqUrl, {}, 1).subscribe((res: any) => {
      console.log("delete product user response ==>", res)
      $('#deleteProduct').modal('hide');
      if (res.code == 200) {
        this.getStoreList()
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
