<div class="wrapper">
    <main class="middle-content">
        <div class="page_title_block">
            <h1 class="page_title">Auction / Add</h1>
        </div>
        <div class="content-section">
            <div class="order-view  max-WT-700 mrgn-0-auto setting-page">
                <div class="  mb40">
                    <div class="add-store-block input-style mb20 mt20 view-label-align">
                        <form [formGroup]="addProductForm" #f="ngForm">
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Category Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <select class="form-control " formControlName="categoryId">
                                            <option value="" selected>Select Category</option>
                                            <option *ngFor="let item of categoryListArray" [value]="item._id">{{item?.categoryName}}</option>
                                        </select>
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addProductForm.get('categoryId').hasError('required') && (addProductForm.get('categoryId').dirty || addProductForm.get('categoryId').touched)">
                                                *Please select category name.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Auction Name <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Auction Name"
                                            formControlName="auctionName" (keypress)="commonService.preventSpace($event)"
                                            maxlength="60">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addProductForm.get('auctionName').hasError('required') && (addProductForm.get('auctionName').dirty || addProductForm.get('auctionName').touched)">
                                                *Please enter auction name.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('auctionName').hasError('pattern') && (addProductForm.get('auctionName').dirty)">
                                                *Auction name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('auctionName').hasError('minlength') && !(addProductForm.get('auctionName').hasError('pattern')) && (addProductForm.get('auctionName').dirty)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('auctionName').hasError('maxlength') && !(addProductForm.get('auctionName').hasError('pattern')) && (addProductForm.get('auctionName').dirty)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Price <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Price"
                                            formControlName="price"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addProductForm.get('price').hasError('required') && (addProductForm.get('price').dirty || addProductForm.get('price').touched)">
                                                *Please enter price.
                                            </span>
                                       
                                            <!-- <span
                                                *ngIf="addProductForm.get('price').hasError('maxlength') && !(addProductForm.get('price').hasError('pattern')) && addProductForm.get('price').dirty ">
                                                *Maximum length should not more than 18.
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Total Entries <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="text" class="form-control" placeholder="Enter Total Entries"
                                            formControlName="totalEntries"
                                            (keypress)="commonService.restrictChar($event)" maxlength="16">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addProductForm.get('totalEntries').hasError('required') && (addProductForm.get('totalEntries').dirty || addProductForm.get('totalEntries').touched)">
                                                *Please enter total entries.
                                            </span>
                                       
                                            <!-- <span
                                                *ngIf="addProductForm.get('totalEntries').hasError('maxlength') && !(addProductForm.get('totalEntries').hasError('pattern')) && addProductForm.get('totalEntries').dirty ">
                                                *Maximum length should not more than 18.
                                            </span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row ">
                                <label class="col-md-5 textalignright ">Description <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Description"
                                            formControlName="description" (keypress)="commonService.preventSpace($event)"
                                            maxlength="256">
                                        <div class="text-danger">
                                            <span
                                                *ngIf="addProductForm.get('description').hasError('required') && (addProductForm.get('description').dirty || addProductForm.get('description').touched)">
                                                *Please enter description.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('description').hasError('pattern') && (addProductForm.get('description').dirty)">
                                                *Description name accept only alphabets.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('description').hasError('minlength') && !(addProductForm.get('description').hasError('pattern')) && (addProductForm.get('description').dirty)">
                                                *Minimum length must be 2.
                                            </span>
                                            <span
                                                *ngIf="addProductForm.get('description').hasError('maxlength') && !(addProductForm.get('description').hasError('pattern')) && (addProductForm.get('description').dirty)">
                                                *Maximum length should not more than 60.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group row align-items-baseline">
                                <label class="col-md-5 textalignright">Auction Image <span>:</span></label>
                                <div class="col-md-7 textaligncenter">
                                    <div class="form-group ">
                                        <input type="file" formControlName="image" name="image"
                                            accept="image/jpg,image/jpeg,image/png" id="image"
                                            (change)="uploadImg($event)">

                                        <img *ngIf="imageUrl" [src]="imageUrl" class="mt-1" alt="">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="text-center mt40 mb40">
                        <button class="btn btn-large  max-WT-150 btn-theme" routerLink="/product-list">Back</button>
                        <button class="btn btn-large  max-WT-150 btn-theme" [disabled]="!addProductForm.valid"
                            (click)="addProduct()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>