import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/provider/common.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editProfileForm: FormGroup;
  file: any;
  imageType: any;
  imageUrl: any;
  
  constructor(private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.editProfileFormValidation();
    this.getProfile();
  }
  editProfileFormValidation() {
    this.editProfileForm = new FormGroup({
      'name': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'mobile': new FormControl('', [Validators.required]),
      'image': new FormControl('')
    })
  }

  // get profile
  getProfile() {
    this.commonService.showSpinner()
    this.commonService.getApi(ApiEndPoint.profile, 1).subscribe((res: any) => {
      console.log("profile response ==>", res);
      if (res.code == 200) {
        this.imageUrl = res.data.profilePic ? res.data.profilePic : ''
        this.editProfileForm.patchValue({
          'name': res.data.name ? res.data.name : '',
          'email': res.data.email ? res.data.email : '',
          'mobile': res.data.mobileNumber ? res.data.mobileNumber : ''
        })
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }


  editProfile() {
    let data = {
      'name': this.editProfileForm.value.name,
      // 'email': this.editProfileForm.value.email,
      'profilePic': this.imageUrl
    }
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editProfile, data, 1).subscribe((res: any) => {
      console.log("add helpline number list response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.commonService.loginData.next(res.data);
        this.router.navigate(['profile'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  // ValidateFileUpload(event) {
  //   this.file = event.target.files;
  //   if (this.file[0]) {
  //     this.imageType = this.file[0].type;
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.imageUrl = e.target.result;
  //     };
  //     reader.readAsDataURL(this.file[0]);
  //   }
  // }

  uploadImg(event): void {
    var img = event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postFormDataApi(ApiEndPoint.uploadFile, fb, 1).subscribe((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.imageUrl = res['data']['secure_url'];
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        // this.imageUrl = res['result']['mediaUrl'];
      }
    })
  }

  back() {
    this.router.navigate(['profile'])
  }
}
