import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/provider/common.service';
import { ApiEndPoint } from 'src/app/config/api-end-point';

@Component({
  selector: 'app-edit-earn-coin',
  templateUrl: './edit-earn-coin.component.html',
  styleUrls: ['./edit-earn-coin.component.scss']
})
export class EditEarnCoinComponent implements OnInit {
  editEarnCoinForm: FormGroup;
  earnCoinId: any;

  constructor(private activatedroute: ActivatedRoute, private router: Router, public commonService: CommonService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.earnCoinId = res.earnCoinId;
    })
    this.editEarnCoinFormValidation();
    this.viewEarnCoin()
  }

  editEarnCoinFormValidation() {
    this.editEarnCoinForm = new FormGroup({
      'title': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/), Validators.minLength(2), Validators.maxLength(60)]),
      'coin': new FormControl('', [Validators.required]),
    })
  }

  // get earn coin detail by id
  viewEarnCoin() {
    let apiReqUrl: any = `${ApiEndPoint.viewEarnCoin + '?_id=' + this.earnCoinId}`
    this.commonService.showSpinner();
    this.commonService.getApi(apiReqUrl, 1).subscribe((res: any) => {
      if (res.code == 200) {
        // this.earnCoinDetails = res.data;
        this.editEarnCoinForm.patchValue({
          'title': res.data.title,
          'coin': res.data.coin
        })
        this.commonService.hideSpinner();
        this.commonService.successToast(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

  editEarnCoin() {
    let data = {
      '_id': this.earnCoinId,
      'title': this.editEarnCoinForm.value.title,
      'coin': this.editEarnCoinForm.value.coin,
    }
    this.commonService.showSpinner();
    this.commonService.putApi(ApiEndPoint.editEarnCoin, data, 1).subscribe((res: any) => {
      console.log("edit earn coin response ==>", res)
      if (res.code == 200) {
        this.commonService.successToast(res.message);
        this.router.navigate(['earn-coin-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.errorToast(res.message)
      }
    })
  }

}
